<template>
    <div class="container-fluid">
        <div class="page-title">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <h3>{{ title }}</h3>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
    },
};
</script>

<style scope>
.page-wrapper .page-body-wrapper .page-title {
    padding: 30px 0px;
    background-color: transparent;
}

.page-title h3 {
    font-size: 25px;
    margin-bottom: 0;
    text-transform: capitalize;
    font-weight: 600;
}
</style>
